<template>
  <div class="page-mates">
    <div class="page-mates__head">
      <Filters @filter="makeFilter" />

      <Sort @toggleFilter="toggleFilter" @sortByPhenotype="sortByPhenotype" />
    </div>

    <button
      class="mates-btn"
      type="button"
      v-if="showFirstCharBtn && isNeighborCharacter"
    >
      <span class="mates-btn__img">
        <CharacterModel :image="characterImage" />
      </span>

      <span class="mates-btn__text">
        {{ characterName }}
      </span>
    </button>

    <section class="mates-mine" v-if="showOwnCsh">
      <div class="page-mates__title">
        {{ $t("mates-my-col") }}
      </div>

      <div class="mates-list">
        <MateCard
          v-for="item in cshOwn"
          :key="item._id"
          :mate="item"
          @click="selectMate"
        />
        <div
          @click="showMyColPopup = true"
          class="mates-char mates-char--btn"
          v-show="cshOwn.length === 4"
        >
          <RoundedButton>
            <template v-slot:icon>
              <ArrowRight />
            </template>
          </RoundedButton>

          <p class="mates-char__text">{{ $t("see-all-btn") }}</p>
        </div>
        <div v-show="cshOwn.length === 4" class="mates-char__space"></div>
      </div>
      <template v-if="!cshOwn.length && loaderDisable">
        {{ $t("no-monsters-for-sale") }}
      </template>
    </section>

    <section class="mates-community" v-if="showCommunityCsh">
      <div class="page-mates__title">
        {{ $t("mates-community-col") }}
      </div>

      <div class="mates-list mates-list--grid">
        <MateCard
          v-for="item in cshCommunity"
          :key="item._id"
          :mate="item"
          @click="selectMate"
          :is-community="true"
        />
      </div>
      <ScrollLoader
        :loader-method="getCommunityCsh"
        :loader-disable="loaderDisable"
      />
      <template v-if="!cshCommunity.length && loaderDisable">
        {{ $t("no-monsters-on-sale") }}
      </template>
    </section>
    <transition name="fade">
      <MyCollection
        v-if="showMyColPopup"
        @close="showMyColPopup = false"
        @selectMate="selectMate"
        :loader-method="loaderMethodToOwnCsh"
      />
    </transition>
  </div>
</template>

<script>
import Filters from "@/components/common/Filters";
import Sort from "@/components/common/Sort";
import RoundedButton from "@/components/common/buttons/RoundedButton";
import ArrowRight from "@/components/icons/ArrowRight";
import MyCollection from "@/components/modals/MyCollection";
import api from "@/api/api";
import SetLoading from "@/mixins/general/SetLoading";
import MateCard from "@/components/breeding/MateCard";
import DefaultScrollLoadingMixin from "@/mixins/common/DefaultScrollLoadingMixin";
import CharacterModel from "../character/СharacterModel";
import LoadShoppieByIdMixin from "../../mixins/loadShoppieByIdMixin";

export default {
  name: "SelectCharacterList",
  mixins: [SetLoading, DefaultScrollLoadingMixin, LoadShoppieByIdMixin],
  props: {
    showOwnCsh: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCommunityCsh: {
      type: Boolean,
      required: false,
      default: true,
    },
    loaderMethodToOwnCsh: {
      type: String,
      required: false,
      default: () => "getUserCryptoshoppies",
    },
    loaderMethodToCommunityCsh: {
      type: String,
      required: false,
      default: () => "",
    },
    showFirstCharBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    cshOwn: [],
    cshCommunity: [],

    isMyCollectionList: true,
    isCommunityList: false,
    isStableList: true,
    showMyColPopup: false,
    filter: "",
    sort: null,
    phenotypeSort: null,
  }),
  computed: {
    isNeighborCharacter() {
      return this.$route.query.neighborCharacter;
    },
  },
  methods: {
    sortByPhenotype(val) {
      this.phenotypeSort = val;
      this.getUserCryptoshoppies();
      this.resetPreviousLoading();
    },
    toggleFilter(val) {
      this.sort = val;
      this.resetPreviousLoading();
    },

    makeFilter(filter) {
      this.filter = filter;
      this.resetPreviousLoading();
    },

    async selectMate(item) {
      this.$emit("selectMate", item);
    },

    async getUserCryptoshoppies() {
      try {
        const response = await api[this.loaderMethodToOwnCsh]({
          skip: 0,
          limit: 4,
          sortByMask: this.phenotypeSort,
        });
        this.cshOwn = response.cryptoshoppies;
      } catch (e) {
        console.log(e);
      }
    },

    async getCommunityCsh() {
      try {
        let priceSort = null;
        let raritySort = null;
        if (this.sort === "price-high-to-low") {
          priceSort = 1;
          raritySort = -1;
        }
        if (this.sort === "price-low-to-high") {
          priceSort = -1;
          raritySort = 1;
        }
        if (this.sort === "rarity-high-to-low") {
          raritySort = 1;
          priceSort = -1;
        }
        if (this.sort === "rarity-low-to-high") {
          raritySort = -1;
          priceSort = 1;
        }
        if (this.sort === "recommended") {
          priceSort = null;
          raritySort = null;
        }
        const response = await api[this.loaderMethodToCommunityCsh]({
          skip: this.skip,
          limit: this.limit,
          filters: this.filter || null,
          price: priceSort,
          rarity: raritySort,
          sortByMask: this.phenotypeSort,
        });
        this.skip += this.limit;
        const transformed = response.cryptoshoppies.map((i) => {
          if (i.cryptoshoppie) {
            return {
              isCommunityCsh: true,
              commission: i.commission,
              price: i.price,
              itemId: i._id,
              ...i.cryptoshoppie,
            };
          }
          return {
            ...i,
            isCommunityCsh: true,
          };
        });
        this.cshCommunity = [...this.cshCommunity, ...transformed];
        this.loaderDisable = transformed.length < this.limit;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.getUserCryptoshoppies();
    if (this.$route.query.neighborCharacter) {
      await this.getCryptoshoppiesById(this.$route.query.neighborCharacter);
    }
    this.setLoading(false);
  },

  components: {
    CharacterModel,
    MateCard,
    MyCollection,
    ArrowRight,
    RoundedButton,
    Sort,
    Filters,
  },
};
</script>
