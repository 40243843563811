<template>
  <SelectCharacterList
    :loader-method-to-community-csh="'getCshForCommunityBreeding'"
    :loader-method-to-own-csh="'getCshForOwnBreeding'"
    :show-first-char-btn="true"
    @selectMate="selectMate"
  />
</template>

<script>
import SelectCharacterList from "../components/lists/SelectCharacterList";
export default {
  name: "Mates",
  methods: {
    async selectMate(item) {
      await this.$router.push({
        name: "Breeding",
        params: item,
      });
    },
  },
  components: { SelectCharacterList },
};
</script>
